import React, {useEffect, useState} from 'react';
import {Select, Tag} from "antd";
import apiRequest from "../general/apiRequest";

const apiUrlRightList                        = "/right/list"
const apiUrlRightListByRoleAndEntity         = "/right/listBy"
const apiUrlSaveRightToEntityRole            = "/right/saveRightTER"

const colorSet = [
  {id: "099ef0a7-249f-4d0a-a53a-1e252c8270c4", code: "L", color: "cyan"},//L cyan
  {id: "7312e725-5e73-4098-a474-17bad318ed3e", code: "U", color: "orange"},//U yellow
  {id: "9729d5d7-b5a0-4908-b5c4-6bc3c12588a2", code: "D", color: "red"},//D red
  {id: "9ad6d2f8-0988-429c-bf7e-85dd5d098ad0", code: "C", color: "yellow"},//C blue
  {id: "afd4301f-f9d0-42f7-aea3-4c4cfc038afc", code: "P", color: "lime"},//P magenta
  {id: "d6f1ea66-6f7a-4eb1-8ef2-2d217b3d000f", code: "R", color: "green"},//R green
]
export const SelectRights = ({entityId = "", roleId, ...props}) => {
  const [rightList, setRightList] = useState([])
  const [entityRights, setEntityRights] = useState([])
//      rights: [{id: "099ef0a7-249f-4d0a-a53a-1e252c8270c4"},{id: "7312e725-5e73-4098-a474-17bad318ed3e"},{id: "9729d5d7-b5a0-4908-b5c4-6bc3c12588a2"},{id: "9ad6d2f8-0988-429c-bf7e-85dd5d098ad0"},{id: "afd4301f-f9d0-42f7-aea3-4c4cfc038afc"},{id: "d6f1ea66-6f7a-4eb1-8ef2-2d217b3d000f"},]
//      rights: ["099ef0a7-249f-4d0a-a53a-1e252c8270c4", "7312e725-5e73-4098-a474-17bad318ed3e", "afd4301f-f9d0-42f7-aea3-4c4cfc038afc"]
  useEffect(() => {
    loadRightList()
    loadEntityRights()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadRightList = () => {
    apiRequest.get(apiUrlRightList)
      .then((rd) => {
        setRightList(rd.data)
      })
      .catch(error => console.log("loadRightList in SelectRights: " + error))
  }
  const loadEntityRights = () => {
    if (roleId && entityId) {
      apiRequest.get(apiUrlRightListByRoleAndEntity + "/" + entityId + "/" + roleId)
        .then((rd) => {
          setEntityRights(rd.data)
        })
        .catch(error => console.log("loadEntityRights in SelectRights: " + error))
    }
  }
  const saveRights = () => {
    if (roleId && entityId) {
      apiRequest.put(apiUrlSaveRightToEntityRole + "/" + this.props.entityId + "/" + this.props.roleId,JSON.stringify(this.state.rights))
        .then((rd) => {
//
        })
        .catch(error => console.log("saveRights in SelectRights: " + error))
    }
  }
  const addRight = (value, opt) => {
    setEntityRights((prevState) => {
      const elements = prevState.find(e => e.id === value)
      if (!elements)
        prevState.push({id: value})
      return [...prevState]
    })
/*
    const rights = this.state.rights
    const elm = rights.find(e => e.id === value)
    if (!elm) {
      rights.push({id: value})
    }
    this.setState({rights: rights})
*/
  }
  const delRight = (value) => {
    setEntityRights((prevState) => {
      return prevState.find(e => e.id !== value)
    })
  }
  const findColor = (value) => {
    if (value) {
      const ret = colorSet.find((e) => e.id === value).color
      if (ret) {return ret} else return "red"
    } else {
      return "green"
    }
  }
  const renderTag = ({ label, value, onClose, ...props}) => {
//    const { label, value, onClose } = props
    const onPreventMouseDown = (event) => { event.preventDefault(); event.stopPropagation() }
//    const color = colorSet.find(e => e.id === value).color
    const color = findColor(value)
    return <Tag
      color={color}
      onMouseDown={onPreventMouseDown}
      onClose={onClose}
      style={{ marginRight: 3}}
      >
      {label}
    </Tag>
  }
  const getValue = () => {
    const value= entityRights.map( (item) => { return item['id'] })
    if (value)
      return value
    else
      return []
  }
  const getValueT = () => {
    entityRights.map((item) => {
      if (item) {
        return item['id']
      } else {
        return null
      }
    })
  }
  // const {roleId, entityId, ...rest} = this.props
  return<Select
    fieldNames={{label: "name", value: "id"}}
    {...props}
    popupMatchSelectWidth={true}
    mode="multiple"
    tagRender={renderTag}
    style={{ width: '100%' }}
    onBlur={saveRights}
    onSelect={addRight}
    onDeselect={delRight}
//    value={entityRights.map( (item) => { return item['id'] })}
    value={getValue()}
    options={rightList}
  />
/*
    <Select.Option key={"0"} value={"0"} disabled={true}>{"Please Choose"}</Select.Option>
    {rightList.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
  </Select>
*/
}

export class SelectRights2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rightList: [],
      rights: [],
//      rights: [{id: "099ef0a7-249f-4d0a-a53a-1e252c8270c4"},{id: "7312e725-5e73-4098-a474-17bad318ed3e"},{id: "9729d5d7-b5a0-4908-b5c4-6bc3c12588a2"},{id: "9ad6d2f8-0988-429c-bf7e-85dd5d098ad0"},{id: "afd4301f-f9d0-42f7-aea3-4c4cfc038afc"},{id: "d6f1ea66-6f7a-4eb1-8ef2-2d217b3d000f"},]
//      rights: ["099ef0a7-249f-4d0a-a53a-1e252c8270c4", "7312e725-5e73-4098-a474-17bad318ed3e", "afd4301f-f9d0-42f7-aea3-4c4cfc038afc"]
    }
  }
  componentDidMount() {
    this.loadRightList()
    this.loadEntityRights()
  }
  loadRightList = async () => {
    const resData = await apiRequest.get(apiUrlRightList)
    this.setState({rightList: resData.data})
  }
  loadEntityRights = async () => {
    if (this.props.roleId && this.props.entityId) {
      const resData = await apiRequest.get(apiUrlRightListByRoleAndEntity + "/" + this.props.entityId + "/" + this.props.roleId)
      this.setState({rights: resData.data})
    }
  }
  saveRights = async () => {
    if (this.props.roleId && this.props.entityId) {
      await apiRequest.put(
        apiUrlSaveRightToEntityRole + "/" + this.props.entityId + "/" + this.props.roleId,
        JSON.stringify(this.state.rights),
      )
    }
  }
  addRight = (value, opt) => {
    const rights = this.state.rights
    const elm = rights.find(e => e.id === value)
    if (!elm) {
      rights.push({id: value})
    }
    this.setState({rights: rights})
  }
  delRight = value => {
    const {rights} = this.state
    const elm = rights.filter(e=> (e.id !== value))
    this.setState({rights: elm})
  }
  renderTag = (props) => { //return ""
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = event => { event.preventDefault(); event.stopPropagation() }
    const color = colorSet.find(e => e.id === value).color
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
//        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3}}
        >
        {label}
      </Tag>
    )
  }
  render() {
    const {roleId, entityId, ...rest} = this.props
    return(
      <Select
        {...rest}
        dropdownAlign={{ points: ["tr", "br"] }}
//        optionLabelProp={"code"}
        popupMatchSelectWidth={true}
        mode="multiple"
//        showArrow
        tagRender={this.renderTag}
        style={{ width: '100%' }}
        onBlur={this.saveRights}
        onSelect={this.addRight}
        onDeselect={this.delRight}
        value={this.state.rights.map( (item) => { return item['id'] })}
      >
        <Select.Option key={"0"} value={"0"} disabled={true}>{"Please Choose"}</Select.Option>
        {this.state.rightList.map(item => (
          <Select.Option
            key={item.id} value={item.id}
//            code={item.code}
          >
            {item.name}
          </Select.Option>
        ))}
      </Select>
    )
  }

}
SelectRights2.defaultProps = {
  entityId: "",
}