import React, {useEffect, useState} from "react"
import {ModalDraggable} from "./ModalDraggable"
import {Button, Col, Form, Input, Popconfirm, Row, Table, Tooltip} from "antd"
import apiRequest from "../general/apiRequest"
import {CatalogTreeWithoutLeafs} from "../common/cmnCatalogTreeWithoutLeafs"
import {
  DeleteOutlined,
  EditOutlined,
  PartitionOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import {isAdminUser} from "../system/checkTenants";
import useCredentials from "../system/UseCredentials";

const apiUrl = {
  catalogShow:            "/catalog/show",
  catalogSave:            "/catalog",
  catalogByParents:       "/catalog/byparents",
  catalogList:            "/catalog/list",
  catalogCreate:          "/catalog",
  catalogDelete:          "/catalog",
}

const newCatalog = {
  id: null,
  active: true,
  position: -1,
  tenantId: null,
  catalogId: null,
  name: "",
}
export const ModalCatalog = ({
    doCatalogVisible = () => window.alert("Function not available."),
    doOpen = () => window.alert("Function not available."),
    ...props}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedParent, setSelectedParent] = useState(null)
  const [loadTime, setLoadTime] = useState(null)
  const treeChange = (tsData, tsLabel, tsExtra) => {
    setSelectedParent(tsData)
  }
  const handleSetSelectedRowKeys = value => {
    setSelectedParent(null)
    setSelectedRowKeys(value)
  }
  return <>
    {props.open
      ?<ModalDraggable
        titleName="Katalog"
        width={"800px"}
        style={{ top: 20, left: 50}}
        footer={null}
        open={props.open}
        // open={props.visible}
        onCancel={() => {props.doOpen(!props.visible)}}
        // onCancel={() => {props.doCatalogVisible(!props.visible)}}
        onOk={() => {props.doOpen(!props.visible)}}
        // onOk={() => {props.doCatalogVisible(!props.visible)}}
      >
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <CatalogFirstLevelTable
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={handleSetSelectedRowKeys}
              loadTime={loadTime}
//              setLoadTime={setLoadTime}
            />
          </Col>
          <Col span={10}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <CatalogTreeWithoutLeafs
                  catalogTreeId={(selectedRowKeys.length > 0)?selectedRowKeys.toString():null}
                  showempty={true}
                  disableempty={false}
                  textempty="--- keine Auswahl ---"
                  allowClear={true}
//                treeCheckable={true}
//                treeCheckStrictly={true}
//                labelInValue={false}
//                treeLine={true}
                  value={selectedParent}
                  onChange={treeChange}
                />
              </Col>
              <Col span={24}>
                <CatalogChildTable
                  parentId={(selectedParent)?selectedParent:((selectedRowKeys.length > 0)?selectedRowKeys.toString():null)}
                  loadTime={loadTime}
                  setLoadTime={setLoadTime}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalDraggable>
      :null
    }
  </>
}
const EditCatalogCell = ({ editing, dataIndex, title, inputType, record, handleSaveCatalog, index, children, ...restProps}) => {
//  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
  return <td {...restProps}>
    {editing
      ?<Form.Item
        name={dataIndex}
        style={{ margin: 0, }}
//        rules={[{required: true, message: `Please Input ${title}!`, }, ]}
      >
        <Input
          onBlur={(e) => {handleSaveCatalog(record.id)}}
          onPressEnter={(e) => {handleSaveCatalog(record.id)}}
          onClick={(e) => {e.stopPropagation()}}
        />
      </Form.Item>
    : children
    }
  </td>
}
const CatalogFirstLevelTable = (props) => {
  const [credentials] = useCredentials()
  const userId = credentials?.user?.id
  const [catalogForm] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  const [catalogs, setCatalogs] = useState([])
  const isEditing = (record) => record.id === editingKey
  useEffect(() => {loadCatalogs()}, [props.loadTime])
  const startEdit = (record) => {
    catalogForm.setFieldsValue({name: '', ...record,})
    setEditingKey(record.id)
  }
  const loadCatalogs = async () => {
      const res = await apiRequest.get(apiUrl.catalogList)
      if (res && res.state && res.data)
        setCatalogs(res.data)
      else setCatalogs([])
  }
  const addCatalogItem = async () => {
//  const addCatalogItem = async parentId => {
//    if (parentId) {
    if (isAdminUser(userId)) {
      const res = await apiRequest.post(`${apiUrl.catalogCreate}`,JSON.stringify({...newCatalog, /*catalogId: parentId, */name: "neuer Eintrag"}))
      if (res && res.state && res.data)
        setCatalogs(catalogs.concat(res.data))
//        props.setLoadTime(Date.now())
    }
  }
  const deleteCatalogItem = async parentId => {
    if (parentId) {
      await apiRequest.delete(`${apiUrl.catalogDelete}/${parentId}`)
      await loadCatalogs()
    }
  }
  const handleSaveCatalog = async key => {
    const newCatalogs = [...catalogs]
    const row = await catalogForm.validateFields()
    const index = newCatalogs.findIndex((item) => key === item.id)
    if (index > -1) {
      const item = {...newCatalogs[index], ...row}
      const res = await apiRequest.put(apiUrl.catalogSave,JSON.stringify(item))
      if (res && res.state && res.data) {
        newCatalogs.splice(index, 1, {...res.data,})
        setCatalogs(newCatalogs)
        setEditingKey('')
      }
    }
  }
  const defaultColumns = [
    {title: "Name", dataIndex: "name", ellipsis: true, width: 170,
        onCell: (row) => {return {onClick: e => {doSelectionChange([row.id])}}},
      editable: true,
    },
    {title: <Tooltip title={"Element hinzufügen"}><Button type={"link"} icon={<PlusOutlined />} onClick={() => addCatalogItem()} disabled={!isAdminUser(userId)} /></Tooltip>,
      dataIndex: "id",
      align: "right",
      render: (id, item) => {
        return !isEditing(item)
          ?(<>
            <Tooltip title={"bearbeiten"}>
              <Button
                type={"link"}
                icon={<EditOutlined/>}
                onClick={() => startEdit(item)}
              />
            </Tooltip>
            {(isAdminUser(userId))
            ?<Popconfirm
              placement="topRight"
              title={"Möchten Sie den Datensatz löschen"}
              onConfirm={() => {
                deleteCatalogItem(id)
              }}
              okText="Ja"
              cancelText="Nein"
              cancelButtonProps={{danger: false, type: "primary"}}
              okButtonProps={{danger: true, ghost: true}}
            >
              <Button type={"link"} danger icon={<DeleteOutlined/>}/>
            </Popconfirm>
            :null}
          </>)
        :null
      }
    }
  ]
  const columns = defaultColumns.map((col) => {
    const {editable, ...rest} = col
    if (!editable) { return col }
    return {
      ...rest,
      onCell: (record) => ({
        record,
        editable: editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        handleSaveCatalog,
        onClick: e => {doSelectionChange([record.id])}
      }),
    }
  })
  const doSelectionChange = value => {
    if (props.selectedRowKeys.toString() !== value.toString())
      props.setSelectedRowKeys(value)
    else
      props.setSelectedRowKeys([])
  }
  return <Form name="catalog-first-level-form" form={catalogForm} component={false}>
  <Table
    pagination={{position: (catalogs.length > 10)?['bottomRight']:[],}}
    rowKey={"id"}
    rowSelection={{
      type: "radio",
      onChange: (selectedKeys) => {doSelectionChange(selectedKeys)},
      selectedRowKeys: props.selectedRowKeys,
      columnWidth: 0,
      renderCell: () => ""
    }}
    components={{body: { cell: EditCatalogCell, },}}
    dataSource={catalogs}
    columns={columns}
    />
  </Form>
}
const CatalogChildTable = (props) => {
  const [credentials] = useCredentials()
  const userId = credentials?.user?.id
  const [childCatalogForm] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  const [childCatalogs, setChildCatalogs] = useState([])
  const [selectedChildRowKeys/*, setSelectedChildRowKeys*/] = useState([])
  const isEditing = (record) => record.id === editingKey
  useEffect(() => {
    loadChildCatalogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentId, props.loadTime])
  const startEdit = (record) => {
    childCatalogForm.setFieldsValue({name: '', ...record,})
    setEditingKey(record.id)
  }
  const loadChildCatalogs = async () => {
    if (props.parentId) {
      const res = await apiRequest.get(`${apiUrl.catalogByParents}/${props.parentId}`)
      if (res && res.state && res.data)
        setChildCatalogs(res.data)
      else setChildCatalogs([])
    } else setChildCatalogs([])
  }
  const addCatalogItem = async parentId => {
    if (parentId) {
      const res = await apiRequest.post(`${apiUrl.catalogCreate}`,JSON.stringify({...newCatalog, catalogId: parentId, name: "neuer Eintrag"}))
      if (res && res.state && res.data)
//        setCatalogs(catalogs.concat(res.data))
      props.setLoadTime(Date.now())
    }
  }
  const handleSaveCatalog = async key => {
    const newChildCatalogs = [...childCatalogs]
    const row = await childCatalogForm.validateFields()
    const index = newChildCatalogs.findIndex((item) => key === item.id)
    if (index > -1) {
      const childItem = {...newChildCatalogs[index], ...row}
      const res = await apiRequest.put(apiUrl.catalogSave,JSON.stringify(childItem))
      if (res && res.state && res.data) {
        newChildCatalogs.splice(index, 1, {...res.data,})
        setChildCatalogs(newChildCatalogs)
        setEditingKey('')
      }
    }
  }
  const deleteCatalogItem = async parentId => {
    if (parentId) {
      await apiRequest.delete(`${apiUrl.catalogDelete}/${parentId}`)
        await loadChildCatalogs()
    }
  }
  const defaultColumns = [
    {title: "Name", dataIndex: "name", ellipsis: true, width: 170,
//        onCell: (row) => {return {onClick: e => {doSelectionChange([row.id])}}},
      editable: true,
    },
    {title: <Tooltip title={"Element hinzufügen"}><Button type={"link"} icon={<PlusOutlined />} onClick={() => addCatalogItem(props.parentId)} /></Tooltip>,
      dataIndex: "id",
      align: "right",
      render: (id, item) => {
        return !isEditing(item)
        ?<>
          <Tooltip title={"bearbeiten"}>
            <Button
              type={"link"}
              icon={<EditOutlined/>}
              onClick={() => startEdit(item)}
            />
          </Tooltip>
          <Tooltip title={"untergeordnetes Element hinzufügen"}>
            <Button type={"link"} icon={<PartitionOutlined/>} onClick={() => {
              addCatalogItem(id)
            }}/>
          </Tooltip>
            {(isAdminUser(userId))
            ?<Popconfirm
              placement="topRight"
              title={"Möchten Sie den Datensatz löschen"}
              onConfirm={() => {deleteCatalogItem(id)}}
              okText="Ja"
              cancelText="Nein"
              cancelButtonProps={{danger: false, type: "primary"}}
              okButtonProps={{danger: true, ghost: true}}
            >
              <Button type={"link"} danger icon={<DeleteOutlined />} />
            </Popconfirm>
            :null}
          </>
          :null
      }
    }
  ]
//   const components = {
//     body: {
// //      row: EditableRow,
//       cell: EditableCell,
//     },
//   }
  const columns = defaultColumns.map((col) => {
    const {editable, ...rest} = col
    if (!editable) {return col}
    return {
      ...rest,
      onCell: (record) => ({
        record,
        editable: editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        handleSaveCatalog,
      }),
    }
  })

  return <Form name="catalog-child-form" form={childCatalogForm} component={false}>
    <Table
//    pagination={{position: ['topRight', 'bottomRight'],}}
//    pagination={{position: (catalogs.length > 10)?['bottomRight']:[],}}
      rowKey={"id"}
      rowSelection={{
        type: "radio",
//      onChange: (selectedKeys) => {doSelectionChange(selectedKeys)},
        selectedRowKeys: /*props.*/selectedChildRowKeys,
        columnWidth: 0,
        renderCell: () => ""
      }}
      components={{body: { cell: EditCatalogCell, },}}
      dataSource={childCatalogs}
      columns={columns}
  />
  </Form>
}