import React from 'react'
import {TextPanel} from "./panelText"
import {PanelMedia} from "./panelMedia"
import {MeasurePanel} from "./MeasurePanel"
import {Button, Card, Popconfirm} from "antd"
import {
  DeleteOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
} from "@ant-design/icons"
import {DragDropContext, Droppable, Draggable} from "@hello-pangea/dnd"
import {CommunicationPanel} from "./panelCommunication"
import {LocationAddressPanel} from "./panelLocationAddress"
import {PersonPanel} from "./panelPerson"
import {AddressPanel} from "./panelAddress"
import {sysRight} from "../common/cmnRights"
import {NodeDataPanel} from "./NodeDataPanel"
import {ContainerMenu} from "./ContainerMenus"
import apiRequest from "../general/apiRequest"
import {connect} from "react-redux";
import {isAdminUser, parentTypeId} from "../system/checkTenants";
import {RaveDamagePanel} from "./panelRaveDamage";
import {RaveMultiAssignmentPanel} from "./panelRaveMultiAssignment";
import {RaveMultiAssignmentPanelRefList} from "./panelRaveMultiAsignmentRef";
import {RaveRelocationPanel} from "./panelRaveRelocation";


export const parentTypes = {
  tenant: "tenant",
  project: "project",
  restorationgoal: "restorationgoal",
  breakdown: "breakdown",
  room: "room",
  workpiece: "workpiece",
  capture: "capture",
  planing: "planing",
  restoration: "restoration",
  image: "image",
  address: "address"
}

const apiUrlPanelList                         = "/panel/byParent"
const apiUrlSaveSortPanelList                 = "/panel/saveSort"
const apiUrlCreateText                        = "/panel/addText"
const apiUrlCreateMedia                       = "/panel/addMedia"
const apiUrlCreateMeasure                     = "/panel/addMeasure"
const apiUrlCreateCommunication               = "/panel/addCommunication"
const apiUrlCreateLocation                    = "/panel/addLocationAddress"
// //TODO Change save person and org in one server call => use other way
const apiUrlCreateOrganisationPerson          = "/panel/addSubject"
const apiUrlCreateRaveDamage                  = "/panel/addRaveDamage"
const apiUrlCreateMultiAssignment             = "/panel/addRaveMultiAssignment"
const apiUrlCreateRelocation                  = "/panel/addRaveRelocation"
const apiUrlCreateNodeData                    = "/panel/addNodeData"

const apiUrlInsertPanel                       = "/panel/insert"

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}
const grid = 2

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

//  csesca no padding/margin
  padding: `${grid}px 0`,
//  padding: grid * 2,
//  margin: `0 0 ${grid}px 0`,

// change background colour if dragging
  background: isDragging ? "#AAA" : "initial",
// background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
//  background: "#FBFBFB",
//  background: isDraggingOver ? "lightblue" : "initial",

//  csesca no padding/margin
//  padding: grid,
  width: "100%"
})

class PanelDndContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {panelList: [], panelChange: (new Date()).toString()}
    this.panelAdding = false;
  }
  componentDidMount = () => {this.loadPanelList(this.props.workId)}
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.workId !== prevProps.workId) {
      this.loadPanelList(this.props.workId)
    }
  }
  handleAddPanel = async (rowType, childRefId) => {
    const url = (() => {
      const workId = this.props.workId
      const projectId = this.props.projectId
      switch (rowType) {
        case "measure": return apiUrlCreateMeasure + "/" + workId
        case "media":
          const projectParam = (projectId)? "&projectId=" + projectId:"" // "&projectId="
          return apiUrlCreateMedia + "?parentId=" + workId + projectParam
        case "communication": return apiUrlCreateCommunication + "/" + workId
        case "location":
        case "address":   return apiUrlCreateLocation + "/" + workId
        case "subjectPerson": //return routeCreateSubjectPerson + "/" + workId + "/" + childRefId
        case "subjectOrganisation": return apiUrlCreateOrganisationPerson + "/" + workId + "/" + childRefId
        case "text": return apiUrlCreateText + "/" + workId
        case "raveDamage": return apiUrlCreateRaveDamage + "/" + workId
        case "raveMultiAssignment": return apiUrlCreateMultiAssignment + "/" + workId
        case "raveRelocation": return apiUrlCreateRelocation + "/" + workId
        case "nodeData": return apiUrlCreateNodeData + "/" + workId +  "/" + childRefId
        default: return ""
      } })()
// TODO Check if Resorting is on right point
    this.panelAdding = true
    if (url !== "") {
      await apiRequest.post(url)
      this.reloadPanel()
    }
  }
  handleInsertPanel = async ({panelType, nodeMetaId, parentId, projectId, position}) => {
    await apiRequest.post(apiUrlInsertPanel,JSON.stringify({panelType, nodeMetaId, parentId, projectId, position,}))
    await this.reloadPanel()
  }
  handleClientDelete = (cType, id) => {this.handleDeletePanel({cType, id})}
  handleDeletePanel = async itemRow => {
    const url = "/" + itemRow.cType + "/"+itemRow.id
    await apiRequest.delete(url)
    this.reloadPanel()
  }
  savePanelPosition = async newSortedPanelList => {
    await apiRequest.put(apiUrlSaveSortPanelList, JSON.stringify(newSortedPanelList))
    this.reloadPanel()
  }
  loadPanelList = async parentId => {
    const panelTypes = "?panelTypes=" + this.props.panelTypes.join("&panelTypes=")
    if (parentId) {
      const rd = await apiRequest.get(apiUrlPanelList + "/" + parentId + panelTypes)
      if (rd && rd.state && rd.data) {
        this.setPanelList(rd.data)
        if (this.panelAdding) {
          this.panelAdding = false
          await this.savePanelPosition(this.state.panelList)
        }
      }
    }
  }
  setPanelList = data => {this.setState({panelList: data})}
  reloadPanel = () => {this.setState({panelChange: (new Date()).toString()}); this.loadPanelList(this.props.workId) }
  getPanel = (rowItem, index, update) => {
    if (rowItem) {
      const corePanel = {
        index: index,
        item: rowItem,
        itemId: rowItem.id,
        rightsParent: this.props.rightsParent,
        editable: this.props.editable,
        projectId: this.props.projectId,
        workId: this.props.workId,
        workParentId: this.props.workParentId,
        panelType: rowItem.cType,
        parentType: this.props.parentType,
        panelTypes: this.props.panelTypes,
        fromTemplate: this.props.fromTemplate,
        viewEntities: this.props.viewEntities,
//        onDelete: this.handleClientDelete,
//        onInsert: this.handleInsertPanel,
      }
      const keyAdd = (this.props.editable)?"View":"Edit"
      const extra = <>
        {(this.props.editable && update && !this.props.fromTemplate)?
        <>
          <ContainerMenu
            savePerson={this.saveResultPersonModalDialog}
            panelTypes={this.props.panelTypes}
            parentType={this.props.parentType}
            projectId={this.props.projectId}
            workId={this.props.workId}
            newIndex={rowItem.position}
            itemId={rowItem.id}
            keyPart={"Append"}
            onAddPanel={this.handleInsertPanel}
            icon={<InsertRowAboveOutlined />}
            panelChange={this.state.panelChange}
          />
          <ContainerMenu
            savePerson={this.saveResultPersonModalDialog}
            panelTypes={this.props.panelTypes}
            parentType={this.props.parentType}
            projectId={this.props.projectId}
            workId={this.props.workId}
            newIndex={rowItem.position + 1}
            itemId={rowItem.id}
            keyPart={"Prepend"}
//            item={rowItem}
//            append={true}
            onAddPanel={this.handleInsertPanel}
            icon={<InsertRowBelowOutlined />}
            panelChange={this.state.panelChange}
          />
          <Popconfirm
            placement="topLeft"
            title={"Möchten Sie den Panel löschen?"}
            okText="Ja"
            cancelText="Nein"
            onConfirm={() => {this.handleClientDelete(rowItem.cType, rowItem.id)}}
            cancelButtonProps={{danger: false, type: "primary"}}
            okButtonProps={{danger: true, ghost: true}}
          >
            <Button type={"text"} icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
        :null
        }</>
      switch (rowItem.cType) {
        case "text":                    return <TextPanel                   extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "media":                   return <PanelMedia extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "measure":                 return <MeasurePanel                extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "communication":           return <CommunicationPanel          extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "location":                return <LocationAddressPanel        extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "subjectPerson":           return <PersonPanel                 extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "subjectOrganisation":     return <PersonPanel                 extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "raveDamage":              return <RaveDamagePanel             extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "raveMultiAssignment":     return <RaveMultiAssignmentPanel    extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "raveRelocation":          return <RaveRelocationPanel         extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "address":                 return <AddressPanel                extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        case "nodeData":                return <NodeDataPanel               extra={extra} {...corePanel} key={"CP" + keyAdd + rowItem.id}/>
        default: return <div>Not Defined</div>
      }
    }
  }
  saveResultPersonModalDialog = values => {
    if (values.result === "ok") {
      this.handleAddPanel(values.kind, values.values.subjectId)
      this.reloadPanel()
    }
  }
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return
    const items = reorder(
      this.state.panelList,
      result.source.index,
      result.destination.index
    )
    this.savePanelPosition(items)
//    this.setState({panelList: items})
  }
  checkDropDisabled = () => {
    return !(this.props.editable && (!this.props.fromTemplate || isAdminUser(this.props.loggedInUser?.user?.id)))
  }

  render = () => {
    const isDropDisabled = this.checkDropDisabled()
    const up = sysRight.UPDATE
    const update = (this.props.rightsParent.indexOf(up) >= 0)
//    const update = (this.props.rightsParent.indexOf(sysRight.UPDATE) >= 0)
    const {panelList} = this.state
//    const panelTypes = new Set(this.props.panelTypes)
    return (
      <Card
        title={this.props.title}
        bordered={this.props.bordered}
        // styles={{body: this.props.bodyStyle, header: this.props.headStyle,}}
//        bodyStyle={this.props.bodyStyle}
//        headStyle={this.props.headStyle}
        styles={{header:this.props.headStyle, body:this.props.bodyStyle}}
        extra={<>
        {(this.props.editable && update && !this.props.fromTemplate)
          ?<>
            <ContainerMenu
              savePerson={this.saveResultPersonModalDialog}
              panelTypes={this.props.panelTypes}
              parentType={this.props.parentType}
              projectId={this.props.projectId}
              workId={this.props.workId}
              newIndex={0}
              itemId={this.props.workId}
              keyPart={"Append"}
              onAddPanel={this.handleInsertPanel}
              icon={<InsertRowAboveOutlined />}
              panelChange={this.state.panelChange}
            />
            <ContainerMenu
              savePerson={this.saveResultPersonModalDialog}
              panelTypes={this.props.panelTypes}
              parentType={this.props.parentType}
              projectId={this.props.projectId}
              workId={this.props.workId}
              newIndex={panelList.length + 1}
              itemId={this.props.workId}
              keyPart={"Prepend"}
              onAddPanel={this.handleInsertPanel}
              icon={<InsertRowBelowOutlined />}
              panelChange={this.state.panelChange}
            />
            </>
          :null}
        </>}
      >
{/*
        <div>
          <div>
*/}
            <RaveMultiAssignmentPanelRefList
              workId={this.props.workId}
              parentType={this.props.parentType}
              parentNodeTypeId={parentTypeId(this.props.parentType)}
            />
{/*
          </div>
*/}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable isDropDisabled={isDropDisabled} droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}>
                  {this.state.panelList.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={isDropDisabled}>
                      {(provided1, snapshot1) => (
                        <div
                          ref={provided1.innerRef}
                          {...provided1.draggableProps}
                          {...provided1.dragHandleProps}
                          style={getItemStyle(
                            snapshot1.isDragging,
                            provided1.draggableProps.style
                          )}
                          className={"draggable-panel-block"}
                          >
                          {this.getPanel(item, index, update)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
{/*
        </div>
*/}
      </Card>
    )
  }
}

PanelDndContainer.defaultProps = {
  fromTemplate: false,
//  isTemplate: false,
  rightsParent: [],
  panelStyle: "default",
  editable: false,
  // editable: true,
  viewEntities: "large",
  title: "",
  bordered: true,
  styles: {body: {}, head: {}},
  bodyStyle: {},
  headStyle: {},
}
const mapStateToProps = state => {
  return {loggedInUser: state.data.loggedInUser}
}

export default connect(mapStateToProps)(PanelDndContainer)