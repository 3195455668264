import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import apiRequest from "../../../general/apiRequest";

const apiUrlImportSchemaTargetListIdentProperty     = "/importSchemaTarget/listIdentProperty"

export const SelectIdentProperty = (props) => {
  const {targetId, ...rest} = props
  const [identProperty, setIdentProperty] = useState([])
  useEffect(() => {
    loadIdentProperties()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetId])
  const loadIdentProperties = () => {
    if (targetId) {
      apiRequest.get(`${apiUrlImportSchemaTargetListIdentProperty}/${targetId}`)
        .then((rd) => {
          if (rd && rd.state && rd.data) setIdentProperty(rd.data)
        })
        .catch(error => console.log("loadIdentProperties in SelectIdentProperty: " + error))
    }
  }
  return <Select
    key={"RtrSelectQuellNodeTarget"+targetId}
    allowClear
    fieldNames={{label: "name", value: "id"}}
    dropdownAlign={{ points: ["tr", "br"] }}
    {...rest}
    options={identProperty}
  />
}